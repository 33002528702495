@import "app/assets/_variables.scss"

@function stripUnits($number)
  @return $number / ($number * 0 + 1)

$marker-width: 40px !default
$marker-height: 40px !default
$marker-border-width: 5px !default
$marker-font-size: 14px !default


.cluster-marker
  position: absolute
  cursor: pointer
  width: $marker-width
  height: $marker-height
  left: -$marker-width / 2
  top: -$marker-height / 2

  border: $marker-border-width solid $marker-cluster-color
  border-radius: 50%
  background-color: white
  text-align: center
  color: black

  font-size: $marker-font-size
  font-weight: bold
  display: flex
  align-items: center
  justify-content: center

.text

// :export
//   markerSize: stripUnits($marker-width)
