$markerWidth: 49px
$markerHeight: 64px

$originX: $markerWidth * .5
$originY: $markerHeight

.simple-marker
  background-image: url('./mapIcon.svg')
  position: absolute
  cursor: pointer
  width: $markerWidth
  height: $markerHeight
  top: -$originY
  left: -$originX
  transform-origin: $originX $originY
  margin: 0
  padding: 0

.simple-marker.featured1
  background-image: url('./mapIcon-featured.svg')
.simple-marker.featured2
  background-image: url('./mapIcon-featured-2.svg')
.simple-marker.featured3
  background-image: url('./mapIcon-featured-3.svg')
.simple-marker.featured4, .simple-marker.featured
  background-image: url('./mapIcon-featured-4.svg')
.simple-marker.featured5
  background-image: url('./mapIcon-featured-5.svg')
.simple-marker.featured6
  background-image: url('./mapIcon-featured-6.svg')
.simple-marker.featured7
  background-image: url('./mapIcon-featured-7.svg')