@import "app/assets/_variables.scss";
  
// must be in sync with baseWidth and baseUnit from Settings.js
$tc-sc-width: 7rem;

$list-z-index: 1;
$advancer-z-index: 2;

// 
// WRAPPER
//
.ribbon-wrapper {
  position: relative;
  padding: 0;
  box-sizing: border-box;
  background-color: #000;
  border-radius: 0.5rem;

  // 
  // CONTAINER
  //
  & > .ribbon-viewport {
    
    /* Make this scrollable when needed */
    overflow-x: auto;
    /* We don't want vertical scrolling */
    overflow-y: hidden;

    /* For WebKit implementations, provide inertia scrolling */
    -webkit-overflow-scrolling: touch;

    /* We don't want internal inline elements to wrap */
    white-space: nowrap;

    /* If JS present, let's hide the default scrollbar */
    /* positioning context for advancers */
    position: relative;
    // font-size: 0;

    /* Make an auto-hiding scroller for the 3 people using a IE */
    -ms-overflow-style: -ms-autohiding-scrollbar;

    /* Remove the default scrollbar for WebKit implementations */
    &::-webkit-scrollbar {
      display: none;
    }

    // 
    // CONTENTS
    //
    & > .ribbon {
      float: left;
      transition: transform 0.2s ease-in-out;
      position: relative;
      padding: .5rem 0;

      &.no-transition {
        -webkit-transition: none;
        transition: none;
      }

      //
      // Список
      //
      & ul {
        
        // это нужно чтобы перекрыть Indicator
        position: relative;
        z-index: $list-z-index;

        // height: 2rem;

        //
        // Элемент списка
        //
        & > li {
          // Я не смог сделать имплементацию при которой li и li>a занимают
          // всю высоту контейнера (ul) - а без этого не получается сделать
          // красивую подсветку элемента при наведении. Почему-то если известна
          // высота, то не срабатывает d-flex align-items-center и содержимое
          // прибито к верху, и никакой гвоздодер не помогает
          // Поэтому, чтобы не мудрствовать лукаво, контейнеру задана не высота,
          // а padding
          // height: 100%;
          // &:hover {
          //   background-color: $color-bordo;
          // }

          margin: 0;

          width: $tc-sc-width;
          overflow-x: hidden;

          // показываем промежуточные бордюрчики
          border-left: 1px solid rgba(white, 0.3);

          // скрываем их для специальных кейсов:
          &:first-child,    // для первого
          &.active,         // для активного
          &.active + li     // для следующего за активным
          {
            border-left: 0;
          }

          //
          // ссылка
          //
          & > a /*, & >a:hover, & > a:visited:hover */ {
            color: rgba(white, 0.7);
            display: block;
            text-align: center;
            width: 100%;

            text-transform: uppercase;
          }
        }
      }

      //
      // Индикатор активного элемента
      //
      & .ribbon-element-indicator {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: $tc-sc-width;
        background-color: $color-bordo;

        transform-origin: 0 0;
        transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
      }
    }
  }

  & > button.advancer {
    /* Reset the button */
    appearance: none;

    // background: transparent;
    background: #000;

    width: 2rem;
    text-align: center;

    z-index: $advancer-z-index;
    padding: 0;
    border: 0;

    /* Now style it as needed */
    position: absolute;
    top: 0;
    bottom: 0;

    /* Set the buttons invisible by default */
    opacity: 0;
    transition: opacity 0.3s;

    &:focus {
      outline: 0;
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    &.left {
      left:0;
      /* top-left | top-right | bottom-right | bottom-left */
      border-radius: 0.5rem 0 0 0.5rem;
    }

    &.right {
      right:0;
      /* top-left | top-right | bottom-right | bottom-left */
      border-radius: 0 0.5rem 0.5rem 0;
    }
  }
}

[data-overflowing="both"] ~ .advancer.left,
[data-overflowing="left"] ~ .advancer.left {
  opacity: 0.7;
}

[data-overflowing="both"] ~ .advancer.right,
[data-overflowing="right"] ~ .advancer.right  {
  opacity: 0.75;
}
