@import "app/assets/_variables.scss";


.tc-stickynavbar-container {
  z-index: $stickynavbar-zindex;
  height: $tc-stickynavbar-height;
  top: $tc-stickynavbar-top;

  & > nav {
    /* top | right | bottom | left */
    padding: 0 $grid-gutter-width 0 $grid-gutter-width + $grid-gutter-width / 2;

  }
}

.manually-expanded .navbar-collapse {
  background-color: $color-beige;
  position: fixed;
  top: $tc-body-top-offset;
  right: 0;
  left: auto;
  width: 30%;
  padding: $grid-gutter-width;
  text-align: right;
}

.navbar.navbar-tc {
  height: $tc-stickynavbar-height;
  width: 100%;

  background-color: $color-beige;

  a, a:hover {
    color: black;
  }

  ul {
    font-size: 0.9rem;

    .active a {
      font-weight: bold;
    }

    .disabled a {
      opacity: 0.3;
    }

  }
}
