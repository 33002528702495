
// Базовые цвета
$color-bordo: #941226;
$color-beige: #fffdf5;
$color-beige-dark: #f7f5ea;
$color-beige-selected: #FADDC8;
$color-blue: #2666e7;

//
// Bootstrap overrides
//

// ---- grid -----
$grid-gutter-width: 20px;

// ---- colors ---- 
// $primary: $color-bordo;

// ---- links -----
$link-color: $color-bordo;

// по умолчанию подчеркиваем...
$link-decoration:                         underline;
$link-hover-decoration:                   none;

// ... но не для навигации!
nav a, a.btn {
    text-decoration: none;
}

// ---- inputs -----
$input-bg: $color-beige;
$input-focus-bg: white;

// $input-focus-border-color: lighten($color-blue, 25%);
// $input-btn-focus-color: rgba($color-blue, 0.25);


//
// Tangocat variables
//

// высота шапки. Нужно для отступов
$tc-stickynavbar-top: 0;
$tc-stickynavbar-height: 48px;
$tc-body-top-offset: $tc-stickynavbar-top + $tc-stickynavbar-height;

$tc-ribbon-height: 56px;

// z-indicies
$stickynavbar-zindex: 300;
$eventlist-zindex: 200;
$stickynavstripe-zindex: 100;

// other
$marker-single-color: #D1203B;
$marker-cluster-color: $color-bordo;

$border-radius-base: 5px;
