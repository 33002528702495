@import '../assets/variables';

.tc-modal {

    .modal-content {
      background-color: $color-beige;
      padding-bottom: 1rem;
    }
  
    .modal-header {
      border-bottom: 0;
      padding-bottom: 0;
  
      .modal-title {
        text-align: center;
        width: 100%;
        color: $color-bordo;
        text-transform: uppercase;
        font-size: 1rem;
      }
  
      button.btn {
        position: absolute;
        right: 1rem;
        opacity: 0.5;
        span {
          svg path {
            fill: #000;
          }
        }
      }
    }
  
    .modal-footer {
      border-top: 0;
      justify-content: center;
    }
  }