@import "app/assets/_variables.scss";

// Идея из этих источников:
// http://jsfiddle.net/X5r8r/927/
// https://stackoverflow.com/a/13588842

// Я так и не понял, нужна ли эта опция. В хроме без нее работает
// html, body {
//     height:100%;
// }

.mapContainer {
  width: 100%;
  height: 100%;
  position: fixed;

  top: $tc-body-top-offset;
  
  left: 0;
  z-index: 0;

  & > .map {
    width: 100%;
    height: 100%;
    box-sizing: content-box;
  }
}
