/*! Tangocat.net (c) 2019 */

// Сначала подключаем переменные. Это нужно сделать строго до подключения Bootstrap
@import "./assets/variables";

// // Бутстрап
@import "~bootstrap/scss/bootstrap.scss";

body {
  background-color: $color-beige;
}

// суперхак: для второстепенных страниц компонент Page добавляет fixed-bottom <footer>
// Чтобы контент не подлазил под него, нужен этот отступ
div#page {
  margin-bottom: 110px;
  // @include media-breakpoint-up(sm) {
  //   footer {
  //     position: fixed;
  //     bottom: 0;
  //     left: 0;
  //     right: 0;
  //     z-index: 1030;
  //   }
  // }
}

footer {
  background-color: $color-beige-dark;
}

// Фикс для бутстрапа
// .form-control.is-invalid, .was-validated .form-control:invalid {
//       background-position-x: right $input-height-inner-quarter !important;
//       background-position-y: center !important;
// }

.row.tc-row-content {
  // отступ для sticky nav
  margin-top: $tc-body-top-offset;
}

.row.tc-row-content,
.row.tc-row-stickynavstripe {
  // выключаем отрицательные gutters, нам не нужно их компенсировать
  @extend .mx-0;
}

.tc-stickynavstripe-container {
  top: $tc-body-top-offset;

  z-index: $stickynavstripe-zindex;
}



span.svg svg {
  vertical-align: text-bottom;
}



.btn.btn-tc {
  @include button-variant($color-bordo, $color-bordo);
}

.btn.btn-outline-tc {
  @include button-outline-variant($color-bordo);

  border-color: rgba($color-bordo, 0.3);
}