@import "app/assets/_variables.scss";

// $header-height: 90px;

$el-border-radius: 5px;
$eventlist-item-color-odd:  $color-beige;
$eventlist-item-color-even: $color-beige-dark;

$event-list-sticky-header-z-index: $eventlist-zindex + 1;
$event-list-cluster-list-overlay-z-index: $eventlist-zindex + 2;
$event-list-cluster-list-z-index: $eventlist-zindex + 3;

.event-list {
    z-index: $eventlist-zindex;
    position: relative;
  
    // это нужно чтобы контент не прыгал когда мы отлепляем .event-list-sticky-header
    &.scrolled ul {
      // padding-top: $header-height;
    }
  
    &.mobile .event-list-closer {
      background-color: $color-bordo;
      border-radius: $el-border-radius;
      width: 3rem;
      height: 3rem;
      bottom: $grid-gutter-width;
      right: $grid-gutter-width * 2;
      opacity: 0.45;
      // нужно переопределить left:0 из бутстраповского класса .fixed-bottom
      left: auto; 
    }

    footer {
      background-color: black;
      color: rgba(white, 0.7);
    
      p.copy {
        border-top: 1px solid rgba(white, 0.3);
        padding-top: 1rem;
      }
    }
}

@keyframes odd {
  0%   { background-color: $color-beige-selected; }
  100% { background-color:  $eventlist-item-color-odd; }
}

@keyframes even {
  0%   { background-color: $color-beige-selected; }
  100% { background-color:  $eventlist-item-color-even; }
}


.event-list ul {
  & li {
    background-color: $eventlist-item-color-odd;
  }

  & li.even {
    background-color: $eventlist-item-color-even;
  }

  & li.selected.odd {
    animation: odd 2s ease-in-out; 
  }

  & li.selected.even {
    animation: even 2s ease-in-out;
  }

  .btn > span.svg {
    padding-right: 0.4rem;
  }
}


.btn-tc-location {
  color: black;
  border-color: rgba(black, 0.1);

  // этот кусок кода нужен для обрезки содержимого
  // но без следующих классов у контейнера он бесполезен: col overflow-hidden flex-nowrap display-table-cell pr-2
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;

  &:hover {
    border-color: black;
  }
}

.btn-tc-link {
  color: $color-blue;
  border-color: rgba($color-blue, 0.2);

  &:hover {
    color: $color-blue;
    border-color: $color-blue;
  }
}


// overrides для кластеризованного списка
.clustered-list {
  position: absolute;
  color: white;
  background-color: black;
  width: 100%;
  z-index: $event-list-cluster-list-z-index;


  ul {

    & li {
      color: white !important;
      background-color: black !important;

      & .btn {
        color: white !important;
        border-color: rgba(white, 0.2) !important;

        &:hover {
          border-color: white !important;
        }
      }

      & .btn-tc-location svg path {
        fill: white;
      }

      & .btn-tc-link svg path,
      & .btn-tc-link svg polyline {
        stroke: white;
      }

    }
  }
}

.clustered-list-overlay {
  // width: 100%;
  // height: 100%;
  background-color: rgba(black, 0.5);
}



.event-list-sticky-header {
  
    .scrolled & {
      position: fixed;
      z-index: $event-list-sticky-header-z-index;
    }
  
    // d.flex
    & > div {
      // height: $header-height;
      
      /* top-left | top-right | bottom-right | bottom-left */
      border-radius: $el-border-radius $el-border-radius 0 0;

      background-color: $color-bordo;
      
      // flex item
      & > div {
        cursor: pointer;

        color: rgba(255, 255, 255, 0.5);
        &.active, &:hover {
          color: white;
        }
      }
    }
  }